import React from 'react'
import { Button, Col, Container, Form, FormGroup, Row } from 'reactstrap'
import Joi from 'joi-browser'
import TreeList from './common/treeList'
import http from '../services/httpService'
import { toast } from 'react-toastify'
import BaseForm from './common/baseForm'

class SearchProfileForm extends BaseForm {
  state = {
    data: {
      id: null,
      locationSelectorList: [],
      name: null,
      userId: '',
      minPrice: 700,
      maxPrice: 1000,
      minPriceList: [],
      maxPriceList: [],
      sizeRangeList: [],
      bedroomsRangeList: [],
      furnishingList: [],
      cityList: [],
      radius: 0,
      radiusList: [],
      searchZipCodeIds: [],
      flexiblePrice: false,
      flexibleSizeRange: false,
      flexibleRoomRange: false,
      flexibleFurnishing: false,
      flexibleZipCodes: false,
      includePaidSources: false,
      excludeSharingNotAllowed: false,
      flexibleSharingNotAllowed: false,
      excludeStudentsNotAllowed: false,
      excludeSeniorHousing: false,
      netSalary: null,
      grossSalary: null,
    },
    tree: [],
    errors: {}
  }

  schema = {
    id: Joi.optional(),
    name: Joi.optional(),
    isActive: Joi.boolean(),
    flexiblePrice: Joi.boolean(),
    flexibleSharingNotAllowed: Joi.boolean(),
    flexibleSizeRange: Joi.boolean(),
    flexibleRoomRange: Joi.boolean(),
    flexibleFurnishing: Joi.boolean(),
    flexibleZipCodes: Joi.boolean(),
    includePaidSources: Joi.boolean(),
    netSalary: Joi.optional(),
    grossSalary: Joi.optional(),
    excludeSharingNotAllowed: Joi.boolean(),
    excludeStudentsNotAllowed: Joi.boolean(),
    excludeSeniorHousing: Joi.boolean(),
    excludeWoningNet: Joi.boolean(),
    excludeKamernet: Joi.boolean(),
    excludeRooms: Joi.boolean(),
    radius: Joi.number(),
    radiusList: Joi.array(),
    locationSelector: Joi.string(),
    locationSelectorList: Joi.array(),
    minPrice: Joi.number().required(),
    maxPrice: Joi.number().required(),
    sizeRangeId: Joi.string().required(),
    cityId: Joi.string().required(),
    roomRangeId: Joi.string().required(),
    furnishingId: Joi.string().required(),
    minPriceList: Joi.array(),
    maxPriceList: Joi.array(),
    sizeRangeList: Joi.array(),
    cityList: Joi.array(),
    bedroomsRangeList: Joi.array(),
    furnishingList: Joi.array(),
    searchZipCodeIds: Joi.array()
      .min(1)
      .label('postcodes'),
    zipCodesTree: Joi.array(),
    createDate: Joi.string(),
    modifiedDate: Joi.string()
  }

  async componentDidMount () {
    const searchProfileId = this.props.match.params.id

    const { data } = this.state
    data.userId = this.props.match.params.userId
    var urlTemplate = `searchProfiles/edit/${this.props.match.params.userId}`
    if (searchProfileId !== 'new') {
      urlTemplate += `/${searchProfileId}`
    }

    const { data: searchProfile } = await http.get(urlTemplate)

    const tree = JSON.parse(JSON.stringify(searchProfile.zipCodesTree))

    this.setState({ data: searchProfile, tree })
  }

  doSubmit = async () => {
    try {
      await http.post(
        'searchprofiles/' + this.props.match.params.userId,
        this.state.data
      )
      toast.success('search profile saved')
      this.props.history.goBack()
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.username = ex.response.data
        this.setState({ errors })
      }
    }
  }

  handleCityChange = async ({ currentTarget: input }) => {
    const { data } = this.state

    data.cityId = input.value
    data.searchZipCodeIds = []

    const { data: tree } = await http.get(
      'searchprofiles/zipcodes/' + input.value
    )
    this.setState({ tree })
  }

  handleTreeChange = (searchZipCodeIds, errors) => {
    console.log(errors)
    const { data } = this.state
    data.searchZipCodeIds = searchZipCodeIds
    this.setState({ data })
  }

  handleDelete = async e => {
    e.preventDefault()

    if (window.confirm('do you want to delete this search profile?')) {
      await http.delete('searchprofiles/' + this.state.data.id)

      toast.success('search profile deleted')

      this.props.history.goBack()
    }
  }

  render () {
    const {
      id,
      minPriceList,
      maxPriceList,
      sizeRangeList,
      bedroomsRangeList,
      furnishingList,
      cityList,
      searchZipCodeIds,
      radiusList,
      locationSelectorList
    } = this.state.data

    return (
      <Container>
        <Form onSubmit={this.handleSubmit}>
          <Row className="mt-4">
            <Col>
              <Row>
                <Col sm="8">
                  <FormGroup row>
                    <Col>{this.renderInput('name', 'Name')}</Col>
                    <Col>
                      {this.renderSelect('minPrice', 'Min price', minPriceList)}
                    </Col>
                    <Col>
                      {this.renderSelect('maxPrice', 'Max price', maxPriceList)}
                    </Col>
                  </FormGroup>

                  <FormGroup row className="my-3">
                    <Col>
                      {this.renderSelect('sizeRangeId', 'Size', sizeRangeList)}
                    </Col>
                    <Col>
                      {this.renderSelect('roomRangeId', 'Room range', bedroomsRangeList)}
                    </Col>

                  </FormGroup>
                  <FormGroup row className="my-3">
                    <Col>
                      {this.renderSelect('furnishingId', 'Furnishing', furnishingList)}
                    </Col>
                    <Col>
                      {this.renderSelect('cityId', 'City', cityList, this.handleCityChange)}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col style={{ paddingLeft: '2.25rem' }}>
                      {this.renderCheckbox('flexibleFurnishing', 'Flexible furnishing')}
                    </Col>
                    <Col style={{ paddingLeft: '2.25rem' }}>
                      {this.renderCheckbox('includePaidSources', 'Include PaidSources')}
                    </Col>
                    <Col>
                      {this.renderCheckbox('excludeSharingNotAllowed', 'Exclude sharing not allowed')}
                    </Col>
                    <Col>
                      {this.renderCheckbox('flexibleSharingNotAllowed', 'Flexible sharing not allowed'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox(
                        'excludeStudentsNotAllowed',
                        'Exclude students not allowed'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox('excludeSeniorHousing', 'Exclude 50+')}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col style={{ paddingLeft: '2.25rem' }}>
                      {this.renderCheckbox('excludeWoningNet', 'Exclude WoningNet')}
                    </Col>
                    <Col style={{ paddingLeft: '2.25rem' }}>
                      {this.renderCheckbox('excludeKamernet', 'Exclude Kamernet')}
                    </Col>
                    <Col style={{ paddingLeft: '2.25rem' }}>
                      {this.renderCheckbox('excludeRooms', 'Exclude Rooms')}
                    </Col>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup row>
                    {this.state.errors['searchZipCodeIds'] && (
                      <div className="alert alert-danger">
                        {this.state.errors['searchZipCodeIds']}
                      </div>
                    )}
                    {searchZipCodeIds && (
                      <TreeList
                        zipCodesTree={this.state.tree}
                        onTreeChange={this.handleTreeChange}
                        searchZipCodeIds={searchZipCodeIds}
                      />
                    )}
                  </FormGroup>
                  <FormGroup row check>
                    <Col>
                      {this.renderCheckbox('flexibleZipCodes', 'Flexible location')}
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row>
                <Col>
                  {this.renderSelect('locationSelector', 'Location selector (defines which method will be used for matching)', locationSelectorList)}
                  {this.renderSelect('radius', 'Radius', radiusList)}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>{this.renderInput('netSalary', 'Net salary', 'number')}</Col>
            <Col>{this.renderInput('grossSalary', 'Gross salary', 'number')}</Col>
          </Row>
          <Row>
            <Col sm="1">{this.renderButton('Save')}</Col>
            <Col sm="1">
              <Button onClick={this.props.history.goBack}> Back </Button>
            </Col>
            {id && (
              <Col>
                <Button onClick={this.handleDelete} color="danger"> Delete </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Container>
    )
  }
}

export default SearchProfileForm
